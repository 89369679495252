import React, { useMemo } from "react";
import { useLocale } from "@insightfulscience/i18";
import Footer from "@insightfulscience/atomic-react/Footer";
import FooterContainer from "@insightfulscience/atomic-react/Footer/Container";
import FooterMainContainer from "@insightfulscience/atomic-react/Footer/Main";
import FooterNav, {
	FooterNavCol,
	FooterNavItemLink,
	FooterNavList,
	FooterNavListItem,
	FooterNavListTitle,
	FooterNavRow,
} from "@insightfulscience/atomic-react/Footer/Nav";
import FooterSideBarContainer from "@insightfulscience/atomic-react/Footer/Sidebar";
import FooterCopyright, {
	FooterCopyrightLink,
	FooterCopyrightText,
} from "@insightfulscience/atomic-react/Footer/Copyright";
import FooterLogo from "@insightfulscience/atomic-react/Footer/Logo";
import FooterSocial, { FooterSocialItemLink } from "@insightfulscience/atomic-react/Footer/Social";

import NextLink from "@insightfulscience/smart-react/NextLink";
import Html from "@insightfulscience/smart-react/Html";
import routes from "../routes";
import { logoAlt, logoPath } from "../prerendered-logo";

const SiteFooter = () => {
	const [t] = useLocale("footer");

	const year = useMemo(() => new Date().getFullYear(), []);

	return (
		<Footer>
			<FooterContainer>
				<FooterMainContainer>
					<FooterNav>
						<FooterNavRow>
							<FooterNavCol>
								<FooterNavListTitle>{t("PRODUCT")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="features-F"
											href="/features"
										>
											{t("FEATURES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="snapgene-viewer-F"
											href="/snapgene-viewer"
										>
											{t("VIEWER")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="convert-file-formats-F"
											href="/features/convert-file-formats"
										>
											{t("CONVERT_FILE_FORMATS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="release-notes-F"
											href="/updates"
										>
											{t("RELEASE_NOTES")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol>
								<FooterNavListTitle>{t("GET_PRODUCT")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="free-trial-F"
											href="/free-trial"
										>
											{t("FREE_TRIAL")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="pricing-F"
											href="/pricing"
										>
											{t("PRICING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="course-licenses-F"
											href="/free-course-license"
										>
											{t("COURSE_LICENSES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} data-qa-selector="iGEM-F" href="/igem">
											{t("IGEM")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol>
								<FooterNavListTitle>{t("RESOURCES")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="academy-F"
											href="/resources"
										>
											{t("SNAPGENE_ACADEMY")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="product-tutorials-F"
											href="/resources"
										>
											{t("PRODUCT_TUTORIALS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink
											data-qa-selector="user-guide-F"
											href="https://support.snapgene.com/hc/en-us/categories/10304176221716"
										>
											{t("USER_GUIDE")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink
											data-qa-selector="common-questions-F"
											href="https://support.snapgene.com/hc/en-us/"
										>
											{t("QUESTIONS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="CRISPR-F"
											href="/guides/design-grna-for-crispr"
										>
											{t("CRISPR")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink data-qa-selector="plasmids-F" href="/plasmids">
											{t("PLASMIDS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink
											data-qa-selector="coronavirus_resources-F"
											href="/plasmids/coronavirus_resources"
										>
											{t("CORONAVIRUS_RESOURCES")}
										</FooterNavItemLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol>
								<FooterNavListTitle>{t("CLONING_TOOLS")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="restriction-cloning-F"
											href="/guides/restriction-enzyme-cloning"
										>
											{t("RESTRICTION_CLONING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="gateway-cloning-F"
											href="/guides/gateway-cloning"
										>
											{t("GATEWAY_CLONING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="ta-cloning-F"
											href="/guides/gibson-assembly"
										>
											{t("GIBSON_ASSEMBLY")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="nebuilder-hifi-F"
											href="/guides/in-fusion-cloning"
										>
											{t("INFUSION_CLONING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="gateway-cloning-F"
											href="/guides/cloning-pcr-products"
										>
											{t("PCR_CLONING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="topo-cloning-F"
											href="/guides/topo-cloning"
										>
											{t("TOPO_CLONING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="golden-gate-assembly-F"
											href="/guides/golden-gate-assembly"
										>
											{t("GOLDEN_GATE")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol>
								<FooterNavListTitle>{t("COMPANY")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="about-F"
											href="/about"
										>
											{t("ABOUT")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="customers-F"
											href="/customers"
										>
											{t("CUSTOMERS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="citations-F"
											href="/guides/citations"
										>
											{t("CITATIONS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink
											data-qa-selector="support-F"
											href="https://support.snapgene.com/hc/en-us/"
										>
											{t("SUPPORT")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink
											data-qa-selector="dotmatics-F"
											href={routes.external.dotmatics}
										>
											{t("DOTMATICS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink
											data-qa-selector="portfolio-F"
											href={routes.external.portfolio}
										>
											{t("PORTFOLIO")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink data-qa-selector="careers-F" href={routes.external.careers}>
											{t("CAREERS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											data-qa-selector="contact-F"
											href="/contact"
										>
											{t("CONTACT")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
						</FooterNavRow>
					</FooterNav>
				</FooterMainContainer>
				<FooterSideBarContainer>
					<FooterCopyright>
						<FooterLogo logoPath={logoPath} logoAlt={logoAlt} />
						<FooterCopyrightText>
							<FooterCopyrightText.CompanyName>
								&copy; {year} {t("COMPANY_NAME")}
							</FooterCopyrightText.CompanyName>
							<FooterCopyrightLink
								data-qa-selector="privacy-policy-F"
								href={routes.external.privacyPolicy}
								spacing={{ mb: 2 }}
								target="_blank"
							>
								{t("PRIVACY_POLICY")}
							</FooterCopyrightLink>
							<br />
							<FooterCopyrightLink
								data-qa-selector="legal-disclaimers-F"
								href="/legal-disclaimers"
								spacing={{ mb: 2 }}
							>
								{t("LEGAL_DISCLAIMERS")}
							</FooterCopyrightLink>
							<br />
							<FooterCopyrightLink
								data-qa-selector="do-not-sell-F"
								href={routes.external.doNotSell}
								spacing={{ mb: 2 }}
								target="_blank"
							>
								<Html Tag="span">{t("DO_NOT_SELL")}</Html>
							</FooterCopyrightLink>
							<br />
							<FooterCopyrightLink
								data-qa-selector="do-not-sell-F"
								href={routes.external.trademarks}
								spacing={{ mb: 2 }}
								target="_blank"
							>
								{t("TRADEMARKS")}
							</FooterCopyrightLink>
						</FooterCopyrightText>
						<FooterSocial>
							<FooterSocialItemLink
								data-qa-selector="twitter-square-F"
								href="https://twitter.com/snapgene/"
								name="twitter-square"
							/>
							<FooterSocialItemLink
								data-qa-selector="facebook-square-F"
								href="https://www.facebook.com/SnapGene/"
								name="facebook-square"
							/>
							<FooterSocialItemLink
								data-qa-selector="linkedin-square-F"
								href="https://www.linkedin.com/company/gsl-biotech/"
								name="linkedin-square"
							/>
						</FooterSocial>
					</FooterCopyright>
				</FooterSideBarContainer>
			</FooterContainer>
		</Footer>
	);
};

export default SiteFooter;
